<template>
    <div>
        <section class="bredcrumb-top padding0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="bredcrumb_wrap">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb-origin">
                                    <li class="breadcrumb-item"><router-link to="/">ホーム</router-link></li>
                                    <li v-for="page in pages" :key="page.path" class="breadcrumb-item"><router-link :to=page.path>{{page.name}}</router-link></li>
                                    <li class="breadcrumb-item" aria-current="page">{{currentName}}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "BreadCrumb",
        props: {
            pages: {
                type: Array,
                default: () => []
            },
            currentName: {
                type: String,
            }
        },
    }
</script>

<style scoped>

</style>